import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter'



const Hero = () => {

    const transition = {type:'spring', duration: 3}
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id='Home'>
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* THE BEST AD */}
                <div className="the-best-ad">
                    <motion.div
                    initial= {{left: mobile? "160px": "238px"}}
                    whileInView= {{left: '8px'}}
                    transition = {{...transition, type: 'tween'}}>

                    </motion.div>
                    <span>the best fitness club in the town</span>
                </div>
                {/* THE HERO HEADING */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal body</span>
                    </div>
                    <div><span>In here we will help you to shape and build your ideal body and live up your life to fullest</span></div>
                </div>
                {/* FIGURES */}
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={140} start={100} delay={4} postFix ="+"></NumberCounter>
                        </span>
                        <span>expert coaches</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={978} start={870} delay={6} postFix ="+"></NumberCounter>
                        </span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={50} start={0} delay={4} postFix ="+"></NumberCounter>
                        </span>
                        <span>fitness programs</span>
                    </div>
                </div>
                {/* HERO BUTTONS */}
                <div className="hero-buttons">
                    <buttons className="btn">Get Started</buttons>
                    <buttons className="btn">Learn More</buttons>
                </div>
                
            </div>
            <div className="right-h">
                <button className="btn">Join Now</button>
                <motion.div 
                initial= {{right: "-1rem"}}
                whileInView= {{right: "4rem"}}
                transition= {transition}
                className="heart-rate">
                    <img src={Heart} alt="Heart" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </motion.div>

                {/* HERO IMAGE */}
                <img src={hero_image} alt="Image of a Guy" className='hero_image'/>
                <motion.img 
                initial = {{right: "11rem"}}
                whileInView = {{right: "20rem"}}
                transition = {transition}
                src={hero_image_back} alt="Two orange curves" className='hero_image_back'/>

                {/* CALORIES */}
                <motion.div
                initial= {{right: "40rem"}}
                whileInView= {{right: "28rem"}}
                transition= {transition}
                className="calories">
                    <img src={Calories} alt="calories image" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>
                    </div>
                </motion.div>
            </div>

        </div>
    )
}

export default Hero