import React from 'react'
import { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'


const Join = () => {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zy9ztz7', 'template_sus25ok', form.current, '287xd_RCG97PBsDP_')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const form = useRef()

  return (
    <div className="Join" id="Join-us">

      <div className="left-j">
        <hr />
        <div>
          <span className='stroke-text'>Ready to </span>
          <span>Ready to </span>
        </div>
        <div>
          <span>Your body </span>
          <span className='stroke-text'>With Us </span>
        </div>
      </div>



      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
          <input type="email" name="user_email" placeholder='Enter your Email address' />
          <button className='btn btn-j'>Join Now</button>
        </form>
      </div>
    </div>
  )
}

export default Join