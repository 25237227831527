import React from 'react'
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'

import { useState } from 'react'

const Header = () => {

  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpend, setMenuOpened] = useState(false);

  return (
    <div className="Header">
      <img src={Logo} alt="Logo The Fit Club" className='logo' />
      {menuOpend === false && mobile === true ? (
        <div 
        style={{backgroundColor: "var(--appColor)", padding : "0.5rem", borderRadius: "5px"}}
        onClick={()=> setMenuOpened(true)}
        >
          <img src={Bars} alt="Bars Icon" style={{width : '1.5rem', height : '1.5rem'}}/>
        </div>
      ) : (
      <ul className='header-menu'>
        <li onClick={()=> setMenuOpened(false)}><Link 
        onClick={()=> setMenuOpened(false)}
        to='Home'
        spy = {true}
        smooth = {true}>Home</Link></li>
          
        <li onClick={()=> setMenuOpened(false)}><Link 
        onClick={()=> setMenuOpened(false)}
        to='Programs'
        spy = {true}
        smooth = {true}>Programs</Link></li>
        <li onClick={()=> setMenuOpened(false)}><Link 
        onClick={()=> setMenuOpened(false)}
        to='Reasons'
        spy = {true}
        smooth = {true}>Why Us</Link></li>
        <li onClick={()=> setMenuOpened(false)}><Link 
        onClick={()=> setMenuOpened(false)}
        to='Plans'
        spy = {true}
        smooth = {true}>Plans</Link></li>
        <li ><Link 
        onClick={()=> setMenuOpened(false)}
        to='Testimonials'
        spy = {true}
        smooth = {true}>Testimonials</Link></li>
      </ul>
      )}
    </div>
  )
}

export default Header