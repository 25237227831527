import React from 'react';
import './Reasons.css';
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";


const Reasons = () => {
    return (
        <div className="Reasons" id="Reasons">
            <div className="left-r">
                <img src={image1} alt="Image 1" />
                <img src={image2} alt="Image 2" />
                <img src={image3} alt="Image 3" />
                <img src={image4} alt="Image 4" />
            </div>
            <div className="right-r">
                <span>Some Reasons</span>

                <div>
                    <span className='stroke-text'>Why </span>
                    <span>choose us</span>
                </div>

                {/* RIGHT SIDE DETAILS OF THE REASONS */}
                <div className="details-r">
                    <div>
                        <img src={tick} alt="" />
                        <span>over 140+ expert coaches</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>train smarter and faster than before</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>1 free program for new member</span>
                    </div>
                    <div>
                        <img src={tick} alt="" />
                        <span>reliable partners</span>
                    </div>
                </div>

                <span style={{
                color: "var(--gray)", 
                fontWeight: "normal", 
                textTransform: "uppercase"
                }}>
                    our partners
                </span>
                <div className="partners">
                    <img src={nb} alt="NB logo" />
                    <img src={nike} alt="Nike Logo" />
                    <img src={adidas} alt="Adidas Logo" />
                </div>
            </div>
        </div>
    )
}

export default Reasons